import React from "react";

const useWindowSize = () => {
  const [windows, setWindows] = React.useState({
    height: null,
    width: null,
  });

  React.useLayoutEffect(() => {
    const updateWindowSize = () =>
      setWindows({
        height: window.innerHeight,
        width: window.innerWidth,
      });

    window.addEventListener("resize", updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);
  return { height: windows.height, width: windows.width };
};

export default useWindowSize;

export const useDivSize = (ref) => {
  const [width, setWidth] = React.useState(null);
  const [height, setHeight] = React.useState(null);

  React.useLayoutEffect(() => {
    const updateDivSize = () => {
      setWidth(ref.current.clientWidth);
      setHeight(ref.current.clientHeight);
    };

    window.addEventListener("resize", updateDivSize);
    setTimeout(function () {
      updateDivSize();
    }, 1);
    return () => window.removeEventListener("resize", updateDivSize);
  }, [ref]);

  return { width: width, height: height };
};

export const useSizeWithChild = (ref, reload = false) => {
  const [width, setWidth] = React.useState(null);
  const [height, setHeight] = React.useState(null);

  React.useLayoutEffect(() => {
    const updateDivSize = () => {
      setTimeout(function () {
        let mainDiv = ref.current;
        let header = mainDiv.querySelector("h2");
        let moreInformation = mainDiv.querySelector(".moreInformation");

        let mHeight = mainDiv.clientHeight;
        let mWidth = mainDiv.clientWidth;
        let chartDivHeight = mHeight - 43;
        if (header) {
          header = header.clientHeight;
          chartDivHeight = chartDivHeight - header;
        }
        if (moreInformation) {
          moreInformation = moreInformation.clientHeight;
          chartDivHeight = chartDivHeight - moreInformation - 2;
        }
        setWidth(mWidth - 42);
        setHeight(chartDivHeight);
      }, 10);
    };

    window.addEventListener("resize", updateDivSize);
    updateDivSize();
    return () => window.removeEventListener("resize", updateDivSize);
  }, [ref, reload]);

  return { width, height };
};
